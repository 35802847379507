ul .nav-link h4 {
    font-weight: 500;
    color: #b2b2b2;
    padding: 0.4rem 1rem;
    border-bottom: 1px rgba(0, 0, 0, 0) solid;
}

ul .nav-link.active h4 {
    color: #000;
    border-bottom: 3px #0057b8 solid;
}

ul .nav-link {
    padding: 0;
}

.viewar {
    text-align: center;
    font-size: 19px;
    /* margin-top: 0.8rem; */
}

.view {
    font-weight: 400;
}

.ar {
    font-weight: 700;
}

.shadows {
    box-shadow: 0px 4px 4px 0px #00000040;
    /* padding-bottom: 1rem; */
    padding: 1rem;
}
.shadows-about {
    padding: 1rem;
}

.sticky{
    top: 0;
    position: sticky;
    z-index: 1000;
    background-color: white;
}

.ls-8 {
    letter-spacing: 0.08rem;
}

.scrollbar {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    overflow-x: auto;
    display: -webkit-box;
    cursor: grab;
}

.scrollbar::-webkit-scrollbar {
    display: none;
}

.list {
    color: #000;
    font-size: 32px;
}

.header-home {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* .scrollbar li {
    margin-right: 1rem;
} */

/* .scrollbar h4 {
    white-space: nowrap !important
} */

.slide-in-right {
    transform: translateX(0%);
    transition: transform 0.5s ease-in-out;
    background-color: beige;
}

@keyframes zoomInOut {
    0% {
        transform: scale(1);
    }
    20% {
        transform: scale(0.95);
    }
    100% {
        transform: scale(1.5);
    }
}

.zoom-in-out {
    animation: zoomInOut 0.8s ease;
    transform-origin: left;
}

@keyframes zoomIn {
    0% {
        transform: scale(1.5);
    }
    80% {
        transform: scale(0.97);
    }
    100% {
        transform: scale(1);
    }
}

.zoom-in {
    animation: zoomIn 0.8s ease;
    transform-origin: left;
}
