@keyframes rotateYAxis {
    0% {
        transform: rotateY(0deg) scale(0);
    }

    100% {
        transform: rotateY(180deg) scale(1.5);
    }
}

@keyframes rotateYAxis1 {
    0% {
        transform: rotateY(0deg) scale(0);
    }
    10% {
        transform: rotateY(-20deg);
    }
    100% {
        transform: rotateY(180deg) scale(1.5);
    }
}

@keyframes scale {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(0.8);
    }
}

.onboard-logo-upper-part {
    transform-origin: center;
    animation: rotateYAxis 0.4s forwards;
}

.onboard-logo-lower-part {
    transform-origin: bottom;
    animation: scale 0.4s forwards;
}
