/* about.module.css */

.modal-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1056;
    transition: transform 0.5s ease-in-out;
}

.modal-content {
    position: fixed;
    top: 50px;
    right: 0;
    bottom: 0;
    width: 100%;
    /* max-width: 400px; Or whatever width you want */
    background: white;
    transform: translateX(100%);
    transition: transform 0.5s ease-in-out;
}

.modal-overlay.slide-in-right {
    transform: translateX(0);
    transition: transform 0.5s ease-in-out;
}
.slide-in-right {
    transform: translateX(0%);
    transition: transform 0.5s ease-in-out;
}
.slide-out-right {
    transform: translateX(100%);
    transition: transform 0.5s ease-in-out;
}

.qq {
    background-color: aqua;
}
