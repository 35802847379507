/* @font-face {
  font-family: 'Gotham';
  src: url("./assets/fonts/GothamMedium.ttf") format('truetype')
} */

@font-face {
  font-family: 'GothamBold';
  src: url('./assets/fonts/GothamBold.ttf') format('truetype');
}

@font-face {
  font-family: 'GothamMedium';
  src: url('./assets/fonts/GothamMedium.ttf') format('truetype');
}

@font-face {
  font-family: 'Gotham';
  src: url('./assets/fonts/GothamMedium.ttf') format('truetype');
}

@font-face {
  font-family: 'GothamBook';
  src: url('./assets/fonts/GothamBook.ttf') format('truetype');
}

body {
  margin: 0;
  /* font-family: 'Gotham';*/
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* max-width: 1080px; */
  margin: 0 auto;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

h1,
h2,
h3,
h4,
h5 {
  margin-bottom: 0;
}

h1 {
  font-size: 38px;
  font-weight: 700;
}

h2 {
  font-size: 28px;
  font-weight: 700;
}

h3 {
  font-size: 21px;
  font-weight: 500;
}

h4 {
  font-size: 18px;
  font-weight: 700;
}

h5 {
  font-size: 12px;
  font-weight: 500;
}

P {
  font-size: 16px;
  font-weight: 400;
}

small {
  font-size: 12px;
  /* font-weight: 500; */
}


table {
  font-size: 12px;
  margin-top: 30px;
  font-weight: 500;
}

.table>tbody:nth-of-type(even) tr {
  --bs-table-accent-bg: #fff;
}

.table>tbody:nth-of-type(odd) tr {
  --bs-table-accent-bg: #E6E8F4;
}



/* tbody{
  --bs-table-accent-bg: #f2f2f2;
} */

.table>:not(caption)>*>* {
  border-bottom-width: 0
}

.check{
  font-size: 4rem;
  color: #5ED35C;
}