.col.help {
    position: fixed; /* 或者 position: absolute; */
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999; /* 這個值可以根據需要調整，只要高於其他元件的 z-index 即可 */
    background-color: white;
}
.start {
    width: 163px;
    height: 44px;
    background-color: #db0025;
    border-radius: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.5s ease-in-out, border-radius 0.5s ease-in-out;
}
@keyframes scaleAndCollapseText {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(0);
    }
}

@keyframes scaleAndCollapseButton {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    50% {
        transform: scale(1);
        opacity: 1;
    }

    100% {
        transform: scale(0);
        opacity: 0;
    }
}

@keyframes scaleAndCollapseCircle {
    0% {
        transform: scale(0);
        opacity: 0;

    }
    10% {
        transform: scale(0.1);
        opacity: 0;

    }
    20% {
        transform: scale(0);
        opacity: 1;


    }

    40% {
        transform: scale(1.1);
    }

    50% {
        transform: scale(0.2);
    }

    100% {
        transform: scale(300);
    }
}

.starttext {
    font-size: 18px;
    font-weight: 700;
    color: white;
    margin: 0;
    transition: transform 0.5s ease-in-out;
}

.fade-out {
    animation: scaleAndCollapseButton 0.6s forwards;
}

.text-collapse {
    animation: scaleAndCollapseText 0.6s;
}

.circle {
    animation: scaleAndCollapseCircle 1.5s;
    /* z-index: 9999; */
}
