.accordion-button:not(.collapsed) {
    box-shadow: none;
    border-color: rgba(0, 0, 0, 0);
    background-color: rgba(0, 0, 0, 0);
    color: #000;
}

.accordion-button::after,
.accordion-button:not(.collapsed)::after {
    background-image: none;
    content: none;
}

.accordion-button:focus {
    border-color: rgba(0, 0, 0, 0);
    box-shadow: none;
}

.tap-expand {
    letter-spacing: 0.08em;
    color: #B2B2B2;
}

.small-title {
    color: #404041;
    letter-spacing: 0.02em;
}

hr {
    color: #F2F2F2;
    opacity: unset;
    margin: 0.2rem 0.4rem;
}

.form-check-input:checked {
    background-color: #0057B8;
    border-color: #0057B8;
}

.form-check-input {
    width: 1.6em;
    height: 1.6em;
    margin-right: 0.6rem;
    margin-top: 0;
}

/* .form-check-inline {
    display: flex;
    flex-direction: row;
    align-items: center;
} */

.form-check-center {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.form-check-label {
    color: #495057;
}

.form-check-center label {
    margin-right: 1rem;
}

.btn-yellow {
    --bs-btn-color: #000;
    --bs-btn-border-color: #B2B2B2;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #FED141;
    --bs-btn-hover-border-color: #FED141;
    --bs-btn-focus-shadow-rgb: 220, 53, 69;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #FED141;
    --bs-btn-active-border-color: #FED141;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #000;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #000;
    --bs-gradient: none;
    width: 73px;
}

label small {
    font-weight: 500;
}

.btn-red {
    --bs-btn-color: #fff;
    --bs-btn-bg: #DB0025;
    --bs-btn-border-color: #DB0025;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #DB0025;
    --bs-btn-hover-border-color: #DB0025;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #DB0025;
    --bs-btn-active-border-color: #DB0025;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #DB0025;
    --bs-btn-disabled-border-color: #DB0025;
}

.btn-red:hover {
    background-color: #A5001C;
}

.btn-white {
    --bs-btn-color: #000;
    --bs-btn-bg: #fff;
    --bs-btn-border-color: #B2B2B2;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #fff;
    --bs-btn-hover-border-color: #B2B2B2;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #fff;
    --bs-btn-active-border-color: #B2B2B2;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #000;
    --bs-btn-disabled-bg: #B2B2B2;
    --bs-btn-disabled-border-color: #B2B2B2;
}

.btn-white:hover {
    border-color: #000000;
    background-color: #fff;
}

.btn.btn-red,
.btn.btn-white {
    border-radius: 12px;
    padding: 1rem 0;
    text-transform: uppercase;
    letter-spacing: 0.08em;
}

/* @media (min-width: 768px) {
    .modal-dialog {
        width: 600px;
        margin: 30px auto;
    }

    .modal-dialog {
        position: relative;
        width: auto;
        margin: 10px;
    }
} */

.modal-dialog {
    position: fixed;
    bottom: 0px;
    right: 0;
    left: 0;
    width: 100%;
    margin: 0 auto;
}


@keyframes animatebottom {
    from {
        bottom: -300px;
        opacity: 0;
    }

    to {
        bottom: 0;
        opacity: 1;
    }
}


.animate-bottom {
    position: relative;
    animation: animatebottom 0.4s;
}

.modal-header {
    border-bottom: none;
    padding-bottom: 0;
    padding-top: 0.8rem;
}

.modal form label {
    text-transform: uppercase;
    letter-spacing: 0.08em;
    font-size: 12px;
    color: #495057;
}

.modal {
    --bs-modal-border-radius: 25px 25px 0 0;
}

.error {
    color: #F03D3E;
    font-size: 12px;
    white-space: nowrap;
    margin-bottom: 0;
}

.border-red {
    border: 1px solid #F03D3E;
}

.form-control::placeholder {
    opacity: 1;
}

.form-control:focus::placeholder {
    opacity: 0;
}

.form-control:disabled {
    background-color: #fff;
}

.btn-white:disabled {
    background: #FFFFFF;
    border: 1px solid #DDE2E5;
    color: #DDE2E5;
}

.swipe-line {
    border-top: 6px solid #D9D9D9;
    border-radius: 24px;
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 0.9rem;
}

.accordion {
    --bs-accordion-border-width: 0px;
}

.accordion-item {
    border: 1px solid #dee2e6 !important;
    /* border: 1px solid #000 !important; */
    border-radius: 0.375rem;
    margin-top: 1rem;
}

.accordion {
    --bs-accordion-btn-bg: none;
}

.form-inscreen {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    overflow-x: auto;
    /* padding-left: 2rem; */
    display: -webkit-box;
    cursor: grab;
}