.header,
.footer {
    position: relative;
}

.header img,
.footer img {
    display: block;
    width: 100%;
    height: auto;
}

.header div {
    position: absolute;
    left: 80px;
    top: 55px;
}

.header small {
    font-size: 18px;
}

.header h3 {
    font-weight: 600;
    font-size: 52px;
}

.salesheet h5 {
    font-size: 28px;
}

.body {
    padding: 20px 24px;
}

.body img {
    width: 100vh;
    max-height: 50vh;
    object-fit: scale-down;
    border-radius: 8px;
    margin: auto;
}

.border {
    margin-top: 30px;
    border: 2px solid #76C2BD;
    border-radius: 8px;
    padding: 42px;
    display: flex;
    align-items: center;
}

.border2 {
    margin-top: 30px;
    border: 2px solid #76C2BD;
    border-radius: 8px;
    padding: 42px;
}

.tag {
    margin-top: 30px;
}

.border img {
    width: 360px;
    height: 100%;
    margin-right: 20px;
}

.border h5 {
    margin-bottom: 8px;
}

.box {
    width: 300px;
    height: 300px;
    background: #E6E8F4;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    margin: 20px;
    text-align: center;
    padding: 0 30px;
    font-weight: 500;
}

.boxs {
    background: #fff;
    /* width: 90%; */
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

@media (max-width:900px) {
    .border img {
        width: 200px;
        height: 100%;
        margin-right: 20px;
    }
}

@media (max-width:800px) {
    .header small {
        font-size: 12px;
    }

    .header h3 {
        font-size: 30px;
    }

    .salesheet h5 {
        font-size: 20px;
    }

    .header div {
        position: absolute;
        left: 60px;
        top: 40px;
    }

    .border {
        display: flex;
        flex-direction: column
    }
}


@media (max-width:602px) {

    .header h3 {
        font-size: 21px;
    }

    .salesheet h5 {
        padding-top: 10px;
        font-size: 16px;
    }

    .header h5{
        padding-top: 0;
    }

    .header div {
        position: absolute;
        left: 30px;
        top: 15px;
    }

    .border, .border2 {
        padding: 10px 10px;
    }
}

.white{
    color: #fff;
}