
@keyframes spread {
    0% {
        transform: scale(0, 0);
    }
    75% {
        transform: scale(1.1, 1.1);
    }
    100% {
        transform: scale(1, 1);
    }
}
.upper-part {
    animation: spread 0.5s 1;
    transform-origin: center top;
}

.lower-part {
    animation: spread 0.5s 1;
    background-color: aquamarine;
    transform-origin: center bottom;
}

.row.logo {
    position: fixed; /* 或者 position: absolute; */
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999; /* 這個值可以根據需要調整，只要高於其他元件的 z-index 即可 */
    background-color: white;
}
